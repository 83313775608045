<template>
  <div class="activityCreate">
    <el-dialog
      v-model="editComParams.isShowEditDialog"
      :title="editComParams.title"
      :width="editComParams.width"
    >
      <el-form
        :model="formData"
        label-width="auto"
        style="max-width: 100%"
        class="formCon"
        ref="ruleFormRef"
        :rules="rules"
      >
        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动名称" prop="activityTitle">
              <el-input v-model="formData.activityTitle" /> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="活动副标题">
              <el-input v-model="formData.activitySubTitle" /> </el-form-item
          ></el-col>
        </el-row>

        <!-- format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss" -->
        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动开始日期" prop="activityStartTime">
              <el-date-picker
                v-model="formData.activityStartTime"
                type="datetime"
                placeholder="请选择活动开始日期"
                style="width: 100%"
                format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="活动结束日期" prop="activityEndTime">
              <el-date-picker
                v-model="formData.activityEndTime"
                type="datetime"
                placeholder="请选择活动结束日期"
                style="width: 100%"
                format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动封面图">
              <el-input v-model="formData.avatarImgUrl" /> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="活动简介">
              <el-input v-model="formData.activityInfo" /> </el-form-item
          ></el-col>
        </el-row>

        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动分类">
              <el-select
                v-model="formData.activityCategoryId"
                placeholder="请选择活动分类"
                size="large"
                style="width: 100%"
                v-if="categoryOptions && categoryOptions.length > 0"
              >
                <el-option
                  v-for="item in categoryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="活动线下类型" prop="activityLineType">
              <el-select
                v-model="formData.activityLineType"
                placeholder="请选择活动线下类型"
                size="large"
                style="width: 100%"
              >
                <el-option
                  v-for="item in lineOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
        </el-row>

        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动付费类型" prop="activityPayType">
              <el-select
                v-model="formData.activityPayType"
                placeholder="请选择活动付费类型"
                size="large"
                style="width: 100%"
              >
                <el-option
                  v-for="item in payOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="活动价格" prop="activityPrice">
              <el-input
                v-model="formData.activityPrice"
                type="number"
                @input="formatNum(formData.activityPrice, 'activityPrice')"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动地址">
              <el-input v-model="formData.activityAddress" /> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="活动人数限制" prop="activityPeopleLimit">
              <el-input
                v-model="formData.activityPeopleLimit"
                type="number"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row class="row-bg" justify="space-between">
          <el-col :span="10">
            <el-form-item label="活动状态" prop="activityStatus">
              <el-select
                v-model="formData.activityStatus"
                placeholder="请选择活动状态"
                size="large"
                style="width: 100%"
              >
                <el-option
                  v-for="item in statusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="10"> </el-col>
        </el-row>

        <el-row class="row-bg" justify="space-between">
          <el-col :span="24">
            <el-form-item label="活动详情">
              <el-input
                v-model="formData.activityDetails"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 10 }"
              /> </el-form-item
          ></el-col>
        </el-row>
      </el-form>

      <div class="btnCon">
        <el-button type="primary" @click="cancelCreate">取消</el-button>
        <el-button type="primary" @click="saveActivity">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
      
  <script>
import { ElMessage } from "element-plus";
export default {
  name: "ActivityCreate",
  props: {
    editComParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    console.log(`ActivityCreate mounted`);
    this.getActivityDetails();
  },
  data() {
    // 自定义验证活动开始时间
    var checkActivityStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动开始日期不能为空"));
      }
      callback();
    };
    // 自定义验证活动结束时间
    var checkActivityEndTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动结束日期不能为空"));
      }
      callback();
    };
    // 自定义验证活动价格
    var checkActivityPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动价格不能为空"));
      }
      callback();
    };
    // 自定义验证活动付费类型
    var checkActivityPayType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动付费类型不能为空"));
      }
      callback();
    };
    // 自定义验证活动线下类型
    var checkActivityLineType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动线下类型不能为空"));
      }
      callback();
    };
    // 自定义验证活动人数限制
    var checkActivityPeopleLimit = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动人数限制不能为空"));
      }
      callback();
    };
    // 自定义验证活动状态
    var checkActivityStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("活动状态不能为空"));
      }
      callback();
    };
    return {
      formData: {
        activityTitle: ``,
        activitySubTitle: ``,
        activityStartTime: ``,
        activityEndTime: ``,
        avatarImgUrl: ``,
        activityInfo: ``,
        activityDetails: ``,
        activityCategoryId: ``,
        activityLineType: ``,
        activityPayType: ``,
        activityPrice: ``,
        activityAddress: ``,
        activityPeopleLimit: ``,
        activityViewNum: ``,
        activityStatus: ``,
      },
      rules: {
        activityTitle: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        activityStartTime: [
          {
            required: true,
            validator: checkActivityStartTime,
            trigger: "blur",
          },
        ],
        activityEndTime: [
          {
            required: true,
            validator: checkActivityEndTime,
            trigger: "blur",
          },
        ],
        activityPrice: [
          {
            required: true,
            validator: checkActivityPrice,
            trigger: "blur",
          },
        ],
        activityLineType: [
          {
            required: true,
            validator: checkActivityLineType,
            trigger: "blur",
          },
        ],
        activityPayType: [
          {
            required: true,
            validator: checkActivityPayType,
            trigger: "blur",
          },
        ],
        activityPeopleLimit: [
          {
            required: true,
            validator: checkActivityPeopleLimit,
            trigger: "blur",
          },
        ],
        activityStatus: [
          {
            required: true,
            validator: checkActivityStatus,
            trigger: "blur",
          },
        ],
      },
      lineOption: [
        {
          value: "1",
          label: "线上活动",
        },
        {
          value: "2",
          label: "线下活动",
        },
      ],
      payOption: [
        {
          value: "1",
          label: "免费",
        },
        {
          value: "2",
          label: "付费",
        },
      ],
      statusOption: [
        {
          value: "1",
          label: "草稿",
        },
        {
          value: "2",
          label: "已发布",
        },
        {
          value: "3",
          label: "已下线",
        },
        {
          value: "4",
          label: "已禁止",
        },
      ],
      categoryOptions: [],
    };
  },
  methods: {
    formatNum(val, key) {
      console.log(`val, key`, val, key);
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.formData[key] = temp;
    },
    // 获取单个活动详情
    getActivityDetails() {
      let that = this;
      console.log(`获取单个活动详情`, that.editComParams.editId);

      that
        .$http({
          url: that.$apis.activity.details.url,
          method: that.$apis.activity.details.method,
          params: {
            id: that.editComParams.editId,
          },
        })
        .then((res) => {
          // 获取分类列表成功
          console.log(`获取单个活动详情`, res);
          if (res && res.code == 0 && res.data) {
            that.formData = {
              ...res.data,
            };
          }
        })
        .catch((err) => {
          console.log(`获取单个活动详情`, err);
        })
        .finally(() => {
          that.getCategoryLists();
        });
    },
    // 获取分类列表
    getCategoryLists() {
      console.log(`获取分类列表`);
      let that = this;
      that
        .$http({
          url: that.$apis.category.lists.url,
          method: that.$apis.category.lists.method,
          params: {},
        })
        .then((res) => {
          // 获取分类列表成功
          console.log(`获取分类列表成功`, res);
          if (res && res.code == 0 && res.data && res.data.length > 0) {
            res.data.forEach((item, index) => {
              that.categoryOptions.push({
                value: item.id,
                label: item.categoryName,
              });
            });
          }
        })
        .catch((err) => {
          console.log(`获取分类列表失败`, err);
        });
    },
    // 点击取消
    cancelCreate() {
      console.log(`点击取消`);
      this.editComParams.isShowEditDialog = false;
    },
    // 点击保存
    saveActivity() {
      console.log(`点击保存,编辑活动`);
      let that = this;
      this.$refs[`ruleFormRef`].validate((valid) => {
        if (valid) {
          console.log(`验证通过`);
          that.editActivityFunc();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editActivityFunc() {
      console.log(`编辑活动`);
      let that = this;
      that
        .$http({
          url: that.$apis.activity.update.url,
          method: that.$apis.activity.update.method,
          data: {
            ...that.formData,
          },
        })
        .then((res) => {
          console.log(`编辑活动成功`, res);
          if (res && res.code == 0) {
            ElMessage.success(`编辑活动成功`);
            that.$emit(`refreshdata`)
            that.editComParams.isShowEditDialog = false;
            
          }
        })
        .catch((err) => {
          console.log(`编辑活动失败`, err);
          ElMessage.error(err.message || `编辑活动失败`);
        });
    },
    resetForm() {
      console.log(`清空异常提示`);
      this.$refs[`ruleFormRef`].resetFields();
    },
  },
};
</script>
      
  <style scoped lang="less">
.activityCreate {
  .formCon {
  }
  .btnCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
</style>
      