<template>
  <div class="categoryEdit">
    <el-dialog
      v-model="editComParams.isShowEditDialog"
      :title="editComParams.title"
      :width="editComParams.width"
    >
      <el-form
        :model="formData"
        label-width="auto"
        style="max-width: 100%"
        class="formCon"
        ref="ruleFormRef"
        :rules="rules"
      >
        <el-row class="row-bg" justify="center">
          <el-col :span="16">
            <el-form-item label="分类名称" prop="categoryName">
              <el-input v-model="formData.categoryName" /> </el-form-item
          ></el-col>
        </el-row>
        <el-row class="row-bg" justify="center">
          <el-col :span="16">
            <el-form-item label="分类别名">
              <el-input v-model="formData.categoryAlias" /> </el-form-item
          ></el-col>
        </el-row>
      </el-form>

      <div class="btnCon">
        <el-button type="primary" @click="cancelEditt">取消</el-button>
        <el-button type="primary" @click="saveCategory">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
      
  <script>
import { ElMessage } from "element-plus";
export default {
  name: "CategoryEditt",
  props: {
    editComParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    console.log(`CategoryEditt mounted`);
    this.getCategoryDetails()
  },
  data() {
    return {
      formData: {
        categoryName: ``,
        categoryAlias: ``,
      },
      rules: {
        categoryName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getCategoryDetails() {
      let that = this;
      console.log(`获取单个分类详情`, that.editComParams.editId);

      that
        .$http({
          url: that.$apis.category.details.url,
          method: that.$apis.category.details.method,
          params: {
            id: that.editComParams.editId,
          },
        })
        .then((res) => {
          // 获取分类列表成功
          console.log(`获取单个分类详情`, res);
          if (res && res.code == 0 && res.data) {
            that.formData = {
              ...res.data,
            };
          }
        })
        .catch((err) => {
          console.log(`获取单个活动详情`, err);
        })
        .finally(() => {
          
        });
    },
    // 点击取消
    cancelEditt() {
      console.log(`点击取消`);
      this.editComParams.isShowEditDialog = false;
    },
    // 点击保存
    saveCategory() {
      console.log(`点击保存,更新分类`);
      let that = this;
      this.$refs[`ruleFormRef`].validate((valid) => {
        if (valid) {
          console.log(`验证通过`);
          that.updateCategoryFunc();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    updateCategoryFunc() {
      console.log(`更新分类`);
      let that = this;
      that
        .$http({
          url: that.$apis.category.update.url,
          method: that.$apis.category.update.method,
          data: {
            ...that.formData,
          },
        })
        .then((res) => {
          console.log(`更新分类成功`, res);
          if (res && res.code == 0) {
            ElMessage.success(`更新分类成功`);
            that.editComParams.isShowEditDialog = false;
            that.$emit(`refreshdata`);
          }
        })
        .catch((err) => {
          console.log(`更新分类失败`, err);
          ElMessage.error(err.message || `更新分类失败`);
        });
    },
    resetForm() {
      console.log(`清空异常提示`);
      this.$refs[`ruleFormRef`].resetFields();
    },
  },
};
</script>
      
  <style scoped lang="less">
.categoryEdit {
  .formCon {
  }
  .btnCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
</style>
      