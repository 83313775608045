import HomePage from "@/pages/Home.vue"
import LoginPage from '@/pages/Login/Login.vue'
import ActivityPage from '@/pages/Activity/ActivityList.vue'

export let activityList={
    path: '/activity',
    component: HomePage,
    id: `activity`,
    icon: ``,
    title: `活动模块`,
    meta: {
        requireAuth: true,
    },
    children: [
        {
            path: 'lists',
            component: ActivityPage,
            id: `lists`,
            icon: ``,
            title: `活动列表`,
            meta: {
                requireAuth: true,
            },
        }
    ]
}