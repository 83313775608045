<template>
  <div class="activityPages">
    <div class="searchCon">
      <div class="inputCon">
        <el-input
          v-model="activityTitle"
          style="width: 240px"
          placeholder="请输入活动名"
        />
      </div>
      <div class="butCon">
        <el-button type="primary" @click="getActivityList">查询</el-button>
        <el-button type="primary" @click="resetSearch">重置</el-button>
      </div>
    </div>
    <div class="buttonCon">
      <el-button type="primary" @click="createActivity">新建</el-button>
    </div>
    <div class="tableCon">
      <el-table
        :data="tableData"
        :border="isBorder"
        style="width: 100%"
        v-loading="isLoading"
      >
        <el-table-column prop="activityTitle" label="活动名" width="180" />
        <el-table-column prop="activityCategoryId" label="分类">
          <template #default="scope">
            <div v-for="(item, index) in categoryOptions" :key="index">
              <div v-if="scope.row.activityCategoryId == item.value">
                {{ item.label }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="activityStartTime" label="活动日期" />
        <el-table-column
          prop="activityPeopleLimit"
          label="活动限制人数"
          width="180"
        />
        <el-table-column prop="operate" label="操作">
          <template #default="scope">
            <el-button
              @click="showEditDialog(scope.row)"
              size="small"
              type="primary"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="delActivity(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationCon">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        @current-change="handleCurrentChange"
      />
    </div>
    <CreateCom
      :createComParams="createComParams"
      v-if="createComParams.isShowCreateDialog"
      @refreshdata="getActivityList"
    ></CreateCom>
    <EditCom
      :editComParams="editComParams"
      v-if="editComParams.isShowEditDialog"
      @refreshdata="getActivityList"
    ></EditCom>
    <el-dialog v-model="isShowDelDialog" title="温馨提示" width="500">
      <span>请确认是否删除当前活动?</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="cancelDelFunc">取消</el-button>
          <el-button type="primary" @click="enterDelFunc"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
    
    <script>
// 引入新建组件
import CreateCom from "@/pages/Activity/components/Create.vue";
import EditCom from "@/pages/Activity/components/Edit.vue";
import { ElMessage } from "element-plus";
export default {
  name: "ActivityListsPages",
  props: {},
  components: {
    CreateCom,
    EditCom,
  },
  data() {
    return {
      tableData: [],
      activityTitle: ``,
      isLoading: false,
      pageNum: 1,
      pageSize: 10,
      pageTotal: 1,
      isBorder: true,
      createComParams: {
        isShowCreateDialog: false,
        title: `新建活动`,
        width: "70%",
      },
      editComParams: {
        isShowEditDialog: false,
        title: `编辑活动`,
        width: "70%",
        editId: null,
      },
      isShowDelDialog: false,
      activityDelId: null,
      categoryOptions: [],
    };
  },
  mounted() {
    console.log(`mounted`);
    this.getActivityList();
  },
  methods: {
    // 获取分类列表
    getCategoryLists() {
      console.log(`获取分类列表`);
      let that = this;
      that.categoryOptions = [];
      that
        .$http({
          url: that.$apis.category.lists.url,
          method: that.$apis.category.lists.method,
          params: {},
        })
        .then((res) => {
          // 获取分类列表成功
          console.log(`获取分类列表成功`, res);
          if (res && res.code == 0 && res.data && res.data.length > 0) {
            res.data.forEach((item, index) => {
              that.categoryOptions.push({
                value: item.id,
                label: item.categoryName,
              });
            });
            console.log(`categoryOptions`, that.categoryOptions);
          }
        })
        .catch((err) => {
          console.log(`获取分类列表失败`, err);
        });
    },
    cancelDelFunc() {
      console.log(`点击取消删除`);
      this.isShowDelDialog = false;
    },
    enterDelFunc() {
      console.log(`点击确认删除`);
      this.delActivityFunc();
    },
    delActivity(row = {}) {
      console.log(`点击删除`, row);
      this.activityDelId = row.id;
      this.isShowDelDialog = true;
    },
    delActivityFunc() {
      let that = this;
      that
        .$http({
          url: that.$apis.activity.delete.url,
          method: that.$apis.activity.delete.method,
          params: {
            id: that.activityDelId,
          },
        })
        .then((res) => {
          console.log(`删除活动成功`, res);
          if (res && res.code == 0) {
            console.log(`已删除`);
            ElMessage.success(`删除成功`);
            that.isShowDelDialog = false;
            that.getActivityList();
          }
        })
        .catch((err) => {
          console.log(`删除活动失败`, err);
          ElMessage.success(`删除失败`);
        })
        .finally(() => {
          console.log(`finally`);
        });
    },
    showEditDialog(row = {}) {
      console.log(`点击编辑`, row, row.id);
      let that = this;
      that.editComParams.editId = row.id;
      that.editComParams.isShowEditDialog = true;
    },
    handleCurrentChange(val) {
      console.log(`监听页面改变 handleCurrentChange`, val);
      let that = this;
      that.pageNum = val || 1;
      that.getActivityList();
    },
    // 新建活动
    createActivity() {
      console.log(`点击新建活动`);
      this.createComParams.isShowCreateDialog = true;
    },
    resetSearch() {
      let that = this;
      console.log(`清空搜索字段`);
      that.activityTitle = ``;
      that.getActivityList();
    },
    getActivityList() {
      let that = this;
      console.log(`获取活动数据`, that.activityTitle);
      that.isLoading = true;
      that
        .$http({
          url: that.$apis.activity.lists.url,
          method: that.$apis.activity.lists.method,
          params: {
            pageNum: that.pageNum,
            pageSize: that.pageSize,
            activityTitle: that.activityTitle || ``,
          },
        })
        .then((res) => {
          console.log(`获取到活动列表结果`, res);
          if (res && res.data && res.data.items && res.data.items.length > 0) {
            that.tableData = res.data.items || [];
          }
          if (res && res.data && res.data.total) {
            that.pageTotal = res.data.total || 1;
          }
        })
        .catch((err) => {
          console.log(`获取活动列表失败`, err);
        })
        .finally(() => {
          that.isLoading = false;
          that.getCategoryLists();
        });
    },
  },
};
</script>
    
    <style scoped lang="less">
.activityPages {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  .searchCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .buttonCon {
    margin-top: 20px;
  }
  .tableCon {
    margin-top: 20px;
    width: 100%;
    flex: 1;
  }
  .paginationCon {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
</style>
    