import storage from "@/utils/storage";

const state = {
    // 存储token
    token: "",
    // 用户信息
    userInfo: {}
}
const getters = {
    getToken(state) {
        return state.token || storage.get("token") || "";
    },
    getUserInfo(state) {
        return state.userInfo || {}
    }
}
const mutations = {
    // 修改token，并将token存入localStorage
    setToken(state, token) {
        state.token = token;
        storage.set('token', token);
        console.log('store、localstorage保存token成功！');
    },
    delToken(state) {
        console.log(`清空token vuex`)
        state.token = "";
        storage.remove("token");
    },
    setUserInfo(state, userInfo = {}) {
        console.log(`设置vuex用户信息`,userInfo)
        state.userInfo = userInfo;
    }
}
const actions = {

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}