// 引入APP和Vue
import { createApp } from 'vue'
import App from '@/App.vue'
// 引入路由
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { routes } from "@/routes/index.js"
// 引入ElementPlus ui组件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 注册icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入api
import { apis } from "@/api/index"
// 引入http
import service from "@/utils/request";

// 注册路由
export const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from, next) => {
    console.log(`路由守卫`, to)
    // 判断要去的路由有没有requiresAuth
    // to.matched.some(r => r.meta.requireAuth) or to.meta.requiresAuth
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (store.state.user.token || store.getters["user/getToken"]) {
            console.log(`有token`)
            next(); //有token,进行request请求，后台还会验证token
        } else {
            console.log(`没有token`, to.fullPath)
            next({
                path: "/login",
                // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由，这要进一步在登陆页面判断
                query: { redirect: to.fullPath }
            });
        }
    } else {
        next(); //如果无需token,那么随它去吧
    }
});

// 引入vuex
import { createStore } from 'vuex'
// 引入store模块化
import { storeModules } from "@/store/index"
// 创建一个新的 store 实例
export const store = createStore({
    modules: {
        ...storeModules
    }
})

// createApp(App).mount('#app')
const app = createApp(App)

// 定义全局变量(全局存储)
app.config.globalProperties.$store = store
// 定义全局变量api
app.config.globalProperties.$apis = apis
// 定义全局变量http请求
app.config.globalProperties.$http = service

// 整个应用支持路由。
app.use(router)
app.use(ElementPlus)
// 引用图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 将 store 实例作为插件安装
app.use(store)

// 挂载组件
app.mount('#app')

