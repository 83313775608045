export let activityApi={
    lists:{
        url:`/activity/lists`,
        method:`get`
    },
    create:{
        url:`/activity/add`,
        method:`post`
    },
    details:{
        url:`/activity/details`,
        method:`get`
    },
    update:{
        url:`/activity/update`,
        method:`post`
    },
    delete:{
        url:`/activity/delete`,
        method:`post`
    },
}