
// 引入页面组件
import LoginPage from '@/pages/Login/Login.vue'
import HomePage from '@/pages/Home.vue'
import { userList } from '@/routes/modules/user'
import { activityList } from '@/routes/modules/activity'
import { categoryList } from '@/routes/modules/category'

export let menuList = [
    {
        ...userList
    },
    {
        ...activityList
    },
    {
        ...categoryList
    }
]

// 定义路由()
export let routes = [
    {
        path: '/',
        component: HomePage,
        id: `user`,
        icon: ``,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/login',
        component: LoginPage,
        id: `login`,
        icon: ``,
    },
    ...menuList
]