import HomePage from "@/pages/Home.vue"
import LoginPage from '@/pages/Login/Login.vue'
import ActivityPage from '@/pages/Activity/ActivityList.vue'

export let userList = {
    path: '/user',
    component: HomePage,
    id: `user`,
    icon: ``,
    title: `用户模块`,
    meta: {
        requireAuth: true,
    },
    children: [
        {
            path: 'admin',
            component: ActivityPage,
            id: `admin`,
            icon: ``,
            title: `后台用户`,
            meta: {
                requireAuth: true,
            },
        },
        {
            path: 'wechatapp',
            component: LoginPage,
            id: `wechatapp`,
            icon: ``,
            title: `小程序用户`,
            meta: {
                requireAuth: true,
            },
        }
    ]
}