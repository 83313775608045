<template>
  <div class="home">
    <div class="top">
      <div class="logoCon">logo</div>
      <div class="userCon" v-if="getUserInfo || getUserInfo.username">
        <div class="avatarImg"></div>
        <div class="userName">{{ getUserInfo.username }}</div>
        <div class="logoutBtn" @click="logoutFunc">退出</div>
      </div>
    </div>
    <div class="bottom">
      <div class="menu">
        <el-menu :unique-opened="isUniqueOpened" :router="routerModules">
          <el-sub-menu
            :index="item.path"
            v-for="(item, index) in menuList"
            :key="index + `-` + item.path"
          >
            <template #title>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item
              :index="item.path + `/` + item2.path"
              v-for="(item2, index2) in item.children"
              :key="index2 + `-` + item2.path"
              >{{ item2.title }}</el-menu-item
            >
          </el-sub-menu>
        </el-menu>
      </div>
      <div class="box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
      
  <script>
import { menuList } from "@/routes/index";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      menuList: menuList,
      isUniqueOpened: true,
      routerModules: true,
    };
  },
  computed: {
    ...mapGetters(`user`, [`getUserInfo`]),
  },
  watch: {
    getUserInfo(newVal, oldVal) {
      console.log(`newVal,oldVal`, newVal, oldVal);
    },
  },
  methods: {
    logoutFunc(){
      let that=this
      console.log(`点击退出登录`)
      that.$store.commit(`user/delToken`);
      that.$router.push("/login");
    }
  },
};
</script>
      
  <style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top {
    width: 100%;
    height: 60x;
    min-height: 60px;
    // border: 1px solid #ccc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .logoCon {
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      // border: 1px solid #ccc;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #a5d8ff;
      font-size: 12px;
      color: #fff;
    }
    .userCon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .avatarImg {
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        // border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #b2f2bb;
        color: #fff;
        font-size: 8px;
      }
      .userName {
        margin-left: 10px;
      }
      .logoutBtn{
        margin-left: 10px;
        color: blue;
      }
    }
  }
  .bottom {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .menu {
      width: 200px;
      min-width: 200px;
      height: 100%;
      border: 1px solid #ccc;
      box-sizing: border-box;
      overflow: auto;
    }
    .box {
      flex: 1;
      height: 100%;
      overflow: auto;
      border: 1px solid #ccc;
      box-sizing: border-box;
    }
  }
}
</style>
      