export let categoryApi={
    lists:{
        url:`/category/lists`,
        method:`get`
    },
    add:{
        url:`/category/add`,
        method:`post`
    },
    details:{
        url:`/category/details`,
        method:`get`
    },
    update:{
        url:`/category/update`,
        method:`post`
    },
    delete:{
        url:`/category/delete`,
        method:`post`
    },
}