import HomePage from "@/pages/Home.vue"
import LoginPage from '@/pages/Login/Login.vue'
import CategoryPage from '@/pages/Category/Category.vue'

export let categoryList={
    path: '/category',
    component: HomePage,
    id: `category`,
    icon: ``,
    title: `分类模块`,
    meta: {
        requireAuth: true,
    },
    children: [
        {
            path: 'lists',
            component: CategoryPage,
            id: `lists`,
            icon: ``,
            title: `分类列表`,
            meta: {
                requireAuth: true,
            },
        }
    ]
}