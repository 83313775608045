<template>
  <div class="login">
    <!-- <el-button type="primary">Primary</el-button> -->
    <!-- 表单区域 -->
    <div class="fromCon">
      <div class="emailCon">
        <span>用户名: </span>
        <el-input
          v-model="userNameValue"
          style="width: 240px"
          placeholder="请输入用户名"
        />
      </div>
      <div class="passwdCon">
        <span>&nbsp;&nbsp;&nbsp;密码: </span>
        <el-input
          v-model="passwdValue"
          style="width: 240px"
          placeholder="请输入密码"
        />
      </div>
    </div>
    <div class="btnCon">
      <el-button
        type="primary"
        round
        size="large"
        style="width: 240px"
        @click="loginFunc"
        >登录
      </el-button>
    </div>
  </div>
</template>
  
  <script>
import { ElMessage } from "element-plus";
export default {
  name: "LoginPage",
  props: {},
  data() {
    return {
      userNameValue: ``,
      passwdValue: ``,
    };
  },
  mounted() {
    console.log(`mounted`, this.$apis.user.login);
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      console.log(`开始获取用户信息`);
      let that = this;
      that
        .$http({
          url: that.$apis.user.userInfo.url,
          method: that.$apis.user.userInfo.method,
        })
        .then((res) => {
          console.log(`获取用户信息成功`, res);
          if (res && res.data && res.data) {
            that.$store.commit(`user/setUserInfo`, res.data);
          }
        })
        .catch((err) => {
          console.log(`获取用户信息失败`, err);
        });
    },
    // 登录
    loginFunc() {
      console.log(`发起一个登录请求`);
      let that = this;
      if (!that.userNameValue) {
        ElMessage.error("请输入邮箱");
        return false;
      }
      if (!that.passwdValue) {
        ElMessage.error("请输入密码");
        return false;
      }
      that
        .$http({
          url: that.$apis.user.login.url,
          method: that.$apis.user.login.method,
          params: {
            username: that.userNameValue,
            password: that.passwdValue,
          },
        })
        .then((res) => {
          console.log(`res`, res);
          ElMessage.success(`登录成功`);
          console.log(`res.data`, res.data);
          if (res.data) {
            that.$store.commit(`user/setToken`, res.data);
            that.getUserInfo();
          }
          that.$router.push("/user");
        })
        .catch((err) => {
          console.log(`err`, err);
          console.log(`登录失败,错误`);
          ElMessage.error(err.message || err || `未知错误`);
        });
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
.login {
  width: 100vw;
  height: 100vh;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .fromCon {
    /* width: 200px; */
    /* height: 300px; */
    /* background-color: red; */
    .emailCon {
    }
    .passwdCon {
      margin-top: 30px;
    }
  }
  .btnCon {
    margin-top: 30px;
    /* width: 200px; */
    /* height: 300px; */
    /* background-color: green; */
  }
}
</style>
  