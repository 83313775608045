<template>
  <div class="categoryPage">
    <div class="searchCon">
      <div class="inputCon">
        <el-input
          v-model="categoryName"
          style="width: 240px"
          placeholder="请输入分类名"
        />
      </div>
      <div class="butCon">
        <el-button type="primary" @click="getCategoryList">查询</el-button>
        <el-button type="primary" @click="resetSearch">重置</el-button>
      </div>
    </div>
    <div class="buttonCon">
      <el-button type="primary" @click="createCategory">新建</el-button>
    </div>
    <div class="tableCon">
      <el-table
        :data="tableData"
        :border="isBorder"
        style="width: 100%"
        v-loading="isLoading"
      >
        <el-table-column prop="categoryName" label="分类名称" width="180" />
        <el-table-column prop="categoryAlias" label="分类别名" width="180" />
        <el-table-column prop="id" label="分类id" />
        <el-table-column prop="operate" label="操作">
          <template #default="scope">
            <el-button
              @click="showEditDialog(scope.row)"
              size="small"
              type="primary"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="delCategory(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <CreateCom
      :createComParams="createComParams"
      v-if="createComParams.isShowCreateDialog"
      @refreshdata="getCategoryList"
    ></CreateCom>
    <EditCom
      :editComParams="editComParams"
      v-if="editComParams.isShowEditDialog"
      @refreshdata="getCategoryList"
    ></EditCom>
    <el-dialog v-model="isShowDelDialog" title="温馨提示" width="500">
      <span>请确认是否删除当前分类?</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="cancelDelFunc">取消</el-button>
          <el-button type="primary" @click="enterDelFunc"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
      
  <script>
// 引入新建组件
import CreateCom from "@/pages/Category/cpmponents/Create.vue";
import EditCom from "@/pages/Category/cpmponents/Edit.vue";
import { ElMessage } from "element-plus";
export default {
  name: "CategoryPage",
  props: {},
  components: {
    CreateCom,
    EditCom,
  },
  data() {
    return {
      categoryName: ``,
      tableData: [],
      isLoading: false,
      isBorder: true,
      createComParams: {
        isShowCreateDialog: false,
        title: `新建分类`,
        width: "50%",
      },
      editComParams: {
        isShowEditDialog: false,
        title: `编辑分类`,
        width: "50%",
        editId: null,
      },
      isShowDelDialog: false,
    };
  },
  mounted() {
    let that = this;
    that.getCategoryList();
  },
  methods: {
    resetSearch(){
        console.log(`点击重置`)
    },
    cancelDelFunc() {
      console.log(`点击取消删除`);
      this.isShowDelDialog = false;
    },
    enterDelFunc() {
      console.log(`点击确认删除`);
      this.delCategoryFunc();
    },
    delCategory(row = {}) {
      console.log(`点击删除`, row);
      this.categoryDelId = row.id;
      this.isShowDelDialog = true;
    },
    delCategoryFunc() {
      let that = this;
      that
        .$http({
          url: that.$apis.category.delete.url,
          method: that.$apis.category.delete.method,
          params: {
            id: that.categoryDelId,
          },
        })
        .then((res) => {
          console.log(`删除活动成功`, res);
          if (res && res.code == 0) {
            console.log(`已删除`);
            ElMessage.success(`删除成功`);
            that.isShowDelDialog = false;
            that.getCategoryList();
          }
        })
        .catch((err) => {
          console.log(`删除活动失败`, err);
          ElMessage.success(`删除失败`);
        })
        .finally(() => {
          console.log(`finally`);
        });
    },
    showEditDialog(row = {}) {
      console.log(`点击编辑`, row, row.id);
      let that = this;
      that.editComParams.editId = row.id;
      that.editComParams.isShowEditDialog = true;
    },
    // 新建分类
    createCategory() {
      console.log(`点击新建分类`);
      this.createComParams.isShowCreateDialog = true;
    },
    getCategoryList() {
      let that = this;
      console.log(`获取分类数据`);
      that.isLoading = true;
      that
        .$http({
          url: that.$apis.category.lists.url,
          method: that.$apis.category.lists.method,
          params: {},
        })
        .then((res) => {
          console.log(`获取分类数据结果`, res);
          if (res && res.code == 0 && res.data && res.data.length > 0) {
            that.tableData = res.data || [];
          }
        })
        .catch((err) => {
          console.log(`获取分类列表失败`, err);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },
  },
};
</script>
      
  <style scoped lang="less">
.categoryPage {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  .searchCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .buttonCon {
    margin-top: 20px;
  }
  .tableCon {
    margin-top: 20px;
    width: 100%;
    flex: 1;
  }
}
</style>
      